import React, { useEffect } from "react";
import { useState, useRef } from "react";
import Api from "../../api/Api";
import { Urls } from "../../api/Urls";
import { Box, Modal, Button, Typography, Chip, TextField } from "@mui/material";
import ContentBox from "../../components/ui/ContentBox";
import ButtonSquare from "../../components/ui/ButtonSquare";
import { toast } from "react-toastify";
import { modalStyle, modalStyleOverflow } from "../../theme/ModalStyle";
import { ErrorMessage, Formik, Form, Field } from "formik";
import FormikErrorText from "../../components/ui/FormikErrorText";
import * as Yup from "yup";

function PrivateLessonCard({ pr, access_token, didDelete, setDidDelete, didUpdate, setDidUpdate }) {
  const [prToUpdate, setPrToUpdate] = useState();
  const [openDeletePr, setOpenDeletePr] = useState(false);
  const [openUpdatePr, setOpenUpdatePr] = useState(false);
  const [players, setPlayers] = useState([[]]);

  const formRef = useRef();

  const [privateLessonData, setPrivateLessonData] = useState({
    duration: "",
    private_lesson_nbr_players: [
      {
        nbr_players: "",
        private_lesson_packs: [
          {
            nbr_lessons: "",
            unit_price: "",
          },
        ],
      },
    ],
  });

  const addElementToInnerArray = (innerArrayIndex, newValue) => {
    const newPlayers = [...players];
    const innerArray = [...newPlayers[innerArrayIndex]];
    innerArray.push(newValue);
    newPlayers[innerArrayIndex] = innerArray;
    setPlayers(newPlayers);
  };

  const removeElementFromInnerArray = (innerArrayIndex, elementIndexToRemove) => {
    const newPlayers = [...players];
    const innerArray = [...newPlayers[innerArrayIndex]];
    innerArray.splice(elementIndexToRemove, 1);
    newPlayers[innerArrayIndex] = innerArray;
    setPlayers(newPlayers);
  };

  const removeElementFromOuterArray = (elementIndexToRemove) => {
    const newPlayers = [...players];
    newPlayers.splice(elementIndexToRemove, 1);
    setPlayers(newPlayers);
  };

  function isObjectEmpty(obj) {
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        return false;
      }
    }
    return true;
  }

  Yup.addMethod(Yup.array, "uniqueNbrLessons", function (message) {
    return this.test("unique-nbr-lessons", message, function (value) {
      const seen = new Set();
      for (let i = 0; i < value?.length; i++) {
        const nbrLessons = value[i]?.nbr_lessons;
        if (seen.has(nbrLessons)) {
          return this.createError({ path: `${this.path}[${i}].nbr_lessons`, message });
        }
        seen.add(nbrLessons);
      }
      return true;
    });
  });

  Yup.addMethod(Yup.array, "uniqueNbrPlayers", function (message) {
    return this.test("unique-nbr-players", message, function (val) {
      const seen = new Set();
      for (let i = 0; i < val?.length; i++) {
        const nbrPlayers = val[i]?.nbr_players;
        if (seen.has(nbrPlayers)) {
          return this.createError({ path: `${this.path}[${i}].nbr_players`, message });
        }
        seen.add(nbrPlayers);
      }
      return true;
    });
  });

  const validationSchemaCreatePrivateLesson = Yup.object().shape({
    duration: Yup.number().typeError("Field must be a number").required("this field is required"),
    private_lesson_nbr_players: Yup.array()
      .of(
        Yup.object().shape({
          nbr_players: Yup.number().typeError("Field must be a number").required("this field is required"),
          private_lesson_packs: Yup.array()
            .of(
              Yup.object().shape({
                nbr_lessons: Yup.number().typeError("Field must be a number").required("this field is required"),
                unit_price: Yup.number().typeError("Field must be a number").required("this field is required"),
              })
            )
            .uniqueNbrLessons("Number of lessons must be unique"),
        })
      )
      .uniqueNbrPlayers("Number of players must be unique"),
  });

  // get single private lesson

  useEffect(() => {
    Api.get(Urls.GET_PRIVATE_LESSONS + `/${pr.id}`, {
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((response) => {
        console.log("private single lesson !!", response?.data?.data);
        setPrivateLessonData(response?.data?.data);

        const numRows = response?.data?.data?.private_lesson_nbr_players?.length || 0;
        // const numCols = response?.data?.data?.private_lesson_nbr_players[0]?.private_lesson_packs.length || 0;
        const mapper = new Array(numRows);

        for (let i = 0; i < numRows; i++) {
          let numCols = response?.data?.data?.private_lesson_nbr_players[i]?.private_lesson_packs.length || 0;
          mapper[i] = new Array(numCols);
          for (let j = 0; j < numCols; j++) {
            mapper[i][j] = 0;
          }
        }
        console.log("mapper", mapper);
        setPlayers(mapper);
        console.log("PLAYERS /::", players);
      })
      .catch((error) => {
        toast.error(error?.response?.data.message);
      });
  }, [openUpdatePr, didUpdate]);

  //delete private lesson
  const handleDeletePr = () => {
    console.log("deleted", access_token);
    console.log("props", access_token);
    Api.delete(Urls.DELETE_PRIVATE_LESSON + `/${pr.id}`, {
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((response) => {
        toast.success("Private lesson deleted successfully");
        setDidDelete(!didDelete);
        console.log(response);
        handleCloseDeletePr();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //delete private lesson pack
  const handleDeletePrPacks = (idPack) => {
    console.log("idpack to delete", idPack);
    Api.delete(Urls.DELETE_PRIVATE_LESSON_PACKS + `/${idPack}`, {
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((response) => {
        toast.success("Private lesson pack deleted successfully");
        setDidDelete(!didDelete);
        console.log(response);
        handleCloseDeletePr();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //delete private lesson number of players
  const handleDeletePrNbPlayers = (idNbPlayers) => {
    console.log("id nbplayers to delete", idNbPlayers);
    Api.delete(Urls.DELETE_PRIVATE_LESSON_NBR_PLAYERS + `/${idNbPlayers}`, {
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((response) => {
        toast.success("Private lesson pack deleted successfully");
        setDidDelete(!didDelete);
        console.log(response);
        handleCloseDeletePr();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // update number of players in private lessons
  const handleUpdateNbPlayers = (idNbPlayers, value) => {
    Api.patch(
      Urls.UPDATE_PRIVATE_LESSON_NBR_PLAYERS + `/${idNbPlayers}`,
      {
        nbr_players: value,
      },
      {
        headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      }
    )
      .then((response) => {
        toast.success("Private lesson number of players updated successfully");
        setDidUpdate(!didUpdate);
        console.log(response);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  // update pack in private lessons
  const handleUpdatePacks = (idPack, values) => {
    console.log("nbrlesson :", values.nbr_lessons);
    console.log("unit price :", values.unit_price);
    console.log("values to send", values);
    Api.patch(Urls.UPDATE_PRIVATE_LESSON_PACKS + `/${idPack}`, values, {
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((response) => {
        toast.success("Private pack updated successfully");
        setDidUpdate(!didUpdate);
        console.log(response);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  // create pack in private lessons
  const handleCreatePacks = (values) => {
    console.log("values to create pack", values);
    Api.post(Urls.CREATE_PRIVATE_LESSON_PACKS, values, {
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((response) => {
        toast.success("Private pack created successfully");
        setDidUpdate(!didUpdate);
        console.log(response);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  // create nbrplayers in private lessons
  const handleCreateNbrPlayers = (values) => {
    console.log("values to create pack", values);
    Api.post(Urls.CREATE_PRIVATE_LESSON_NBR_PLAYERS, values, {
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((response) => {
        toast.success("Private number of players created successfully");
        setDidUpdate(!didUpdate);
        console.log(response);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  // update private lesson
  const handleUpdatePr = () => {
    console.log("updated");
  };

  //close modal delete
  const handleCloseDeletePr = () => {
    setOpenDeletePr(false);
  };

  //open modal delete
  const handleOpenDeletePr = () => {
    setOpenDeletePr(true);
  };

  //close modal update
  const handleCloseUpdatePr = () => {
    setOpenUpdatePr(false);
  };

  //open modal update
  const handleOpenUpdatePr = () => {
    setOpenUpdatePr(true);
  };

  return (
    <ContentBox width="470px" display="flex" flexDirection="column" gap="24px">
      <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
        <Box>
          <Typography fontsize="18px" fontWeight="600" color="#4B465C">
            PRIVATE LESSONS {pr?.duration}'
          </Typography>
        </Box>
        <Box display="flex" flexDirection="row" gap="10px">
          <ButtonSquare
            action="edit_outlined"
            onClick={() => {
              handleOpenUpdatePr();
            }}
          />
          <ButtonSquare action="delete_red" onClick={handleOpenDeletePr} />
        </Box>
      </Box>

      {pr?.private_lesson_nbr_players.map((item, index) => (
        <Box key={index}>
          <Typography maxWidth="250px" marginBottom="16px" fontSize="18px" fontWeight="500" color="#4B465C">
            {item?.nbr_players} player{item?.nbr_players === 1 ? "" : "s"} packs
          </Typography>
          <Box>
            {item?.private_lesson_packs.map((pack, index) => (
              <Box key={index} display="flex" flexDirection="row" justifyContent="space-between" marginBottom="16px">
                <Chip variant="green" label={`${pack.nbr_lessons} sessions`} />
                <Typography>{pack.unit_price} BD</Typography>
              </Box>
            ))}
          </Box>
        </Box>
      ))}

      {/* modal delete */}
      <Modal open={openDeletePr} onClose={handleCloseDeletePr}>
        <Box sx={modalStyleOverflow}>
          <Box position="absolute" top="2px" right="2px">
            <ButtonSquare action="close" onClick={handleCloseDeletePr} />
          </Box>
          <Box marginBottom="26px">
            <Typography fontSize="26px" lineHeight="36px" fontWeight="500" color="#4B465C">
              Delete private lesson
            </Typography>
          </Box>
          <Box>
            <Typography fontSize="22px" lineHeight="32px" fontWeight="500" color="#4B465C">
              Are you sure you want to delete this private lesson?
            </Typography>
          </Box>
          <Box marginTop="16px" display="flex" gap="16px" flexDirection="row-reverse">
            <Button onClick={handleCloseDeletePr} variant="primary_inactive">
              Cancel
            </Button>
            <Button onClick={handleDeletePr} variant="primary_active">
              Delete
            </Button>
          </Box>
        </Box>
      </Modal>

      {/* modal update */}
      <Modal open={openUpdatePr} onClose={handleCloseUpdatePr}>
        <Box sx={modalStyleOverflow} overflow="scroll">
          <Box position="absolute" top="2px" right="2px">
            <ButtonSquare action="close" onClick={handleCloseUpdatePr} />
          </Box>
          <Box marginBottom="26px">
            <Typography fontSize="26px" lineHeight="36px" fontWeight="500" color="#4B465C">
              Update private lesson for duration {pr.duration}'
            </Typography>
          </Box>
          <Formik innerRef={formRef} onSubmit={handleUpdatePr} validationSchema={validationSchemaCreatePrivateLesson} initialValues={privateLessonData} enableReinitialize={true}>
            {({ errors, setFieldValue, values, formik }) => (
              <Form>
                <Box>
                  {/* duration field */}
                  {/* <pre>{JSON.stringify(values, null, 2)}</pre>
                  <pre>{JSON.stringify(errors, null, 2)}</pre> */}
                  {/* <Box sx={{ width: { xs: "100%", md: "50%" } }} marginBottom="10px">
                    <Typography fontSize="13px" fontWeight="400" color="#4B465C">
                      Duration
                    </Typography>
                    <Field fullWidth width="100%" name="duration" type="input" id="duration" as={TextField} autoComplete="off" placeholder="duration" />
                    <ErrorMessage component={FormikErrorText} name="duration" />
                  </Box> */}
                </Box>

                {/* nb players */}
                {players?.map((player, indexPlayer) => (
                  <Box width="100%" key={indexPlayer} display="flex" flexDirection="column" gap="16px" sx={{ width: "100%", padding: "16px", bgcolor: "#F3F3F3", borderRadius: "6px", marginBottom: "16px" }}>
                    {indexPlayer !== 0 && (
                      <Box display="flex" flexDirection="row-reverse" gap="10px">
                        <ButtonSquare
                          action="delete_red"
                          onClick={() => {
                            if (values?.private_lesson_nbr_players[indexPlayer]?.id !== undefined) {
                              removeElementFromOuterArray(indexPlayer);
                              setFieldValue(
                                `private_lesson_nbr_players`,
                                values.private_lesson_nbr_players.filter((obj) => obj !== values.private_lesson_nbr_players[indexPlayer])
                              );
                              handleDeletePrNbPlayers(values.private_lesson_nbr_players[indexPlayer].id);
                            } else {
                              removeElementFromOuterArray(indexPlayer);
                              setFieldValue(
                                `private_lesson_nbr_players`,
                                values.private_lesson_nbr_players.filter((obj) => obj !== values.private_lesson_nbr_players[indexPlayer])
                              );
                            }
                          }}
                        />
                      </Box>
                    )}
                    <Box display="flex" flexDirection="row" alignItems="center" gap="6px">
                      <Box>
                        <Typography fontSize="13px" fontWeight="400" color="#4B465C">
                          Number of players
                        </Typography>
                        <Field fullWidth width="100%" name={`private_lesson_nbr_players[${indexPlayer}].nbr_players`} type="input" id="nbr_players" as={TextField} autoComplete="off" placeholder="Number of players" />
                        <ErrorMessage component={FormikErrorText} name={`private_lesson_nbr_players[${indexPlayer}].nbr_players`} />
                      </Box>

                      <Button
                        variant="primary_active"
                        sx={{ height: "38px", marginBottom: "-15px" }}
                        onClick={() => {
                          if (values?.private_lesson_nbr_players[indexPlayer]?.id !== undefined) {
                            handleUpdateNbPlayers(values?.private_lesson_nbr_players[indexPlayer]?.id, values?.private_lesson_nbr_players[indexPlayer]?.nbr_players);
                          } else {
                            if (values?.private_lesson_nbr_players[indexPlayer]?.private_lesson_packs !== undefined || values?.private_lesson_nbr_players[indexPlayer]?.nbr_players !== undefined) {
                              handleCreateNbrPlayers({
                                private_lesson_id: values?.id,
                                nbr_players: values?.private_lesson_nbr_players[indexPlayer]?.nbr_players,
                                private_lesson_packs: values?.private_lesson_nbr_players[indexPlayer]?.private_lesson_packs,
                                // nbr_lessons: values?.private_lesson_nbr_players[indexPlayer]?.private_lesson_packs[indexPack]?.nbr_lessons,
                                // unit_price: values?.private_lesson_nbr_players[indexPlayer]?.private_lesson_packs[indexPack]?.unit_price,
                              });
                            } else {
                              console.log("some fields are undefined");
                              console.log("nbr players ", values?.private_lesson_nbr_players[indexPlayer]?.nbr_players);
                              console.log("pr lessons packs", values?.private_lesson_nbr_players[indexPlayer]?.private_lesson_packs);
                            }
                          }
                        }}
                      >
                        Save
                      </Button>
                    </Box>

                    {player?.map((pack, indexPack) => (
                      <Box key={indexPack} display="flex" flexDirection="column">
                        <Box display="flex" flexDirection="row" alignItems="center" gap="10px">
                          <Box>
                            <Typography fontSize="13px" fontWeight="400" color="#4B465C">
                              Number of sessions
                            </Typography>
                            <Field width="100%" name={`private_lesson_nbr_players[${indexPlayer}].private_lesson_packs[${indexPack}].nbr_lessons`} type="input" id="nbr_players" as={TextField} autoComplete="off" placeholder="Number of sessions" />
                            <ErrorMessage component={FormikErrorText} name={`private_lesson_nbr_players[${indexPlayer}].private_lesson_packs[${indexPack}].nbr_lessons`} />
                          </Box>
                          <Box display="flex" flexDirection="row" alignItems="center" gap="6px">
                            <Box>
                              <Typography fontSize="13px" fontWeight="400" color="#4B465C">
                                Slot price
                              </Typography>
                              <Field width="100%" name={`private_lesson_nbr_players[${indexPlayer}].private_lesson_packs[${indexPack}].unit_price`} type="input" id="nbr_players" as={TextField} autoComplete="off" placeholder="Unit price" />
                              <ErrorMessage component={FormikErrorText} name={`private_lesson_nbr_players[${indexPlayer}].private_lesson_packs[${indexPack}].unit_price`} />
                            </Box>
                            {{ indexPack } && (
                              <Box display="flex" flexDirection="row-reverse" marginBottom="-20px" gap="6px">
                                {indexPack !== 0 && (
                                  <ButtonSquare
                                    action="delete_red"
                                    onClick={() => {
                                      if (values?.private_lesson_nbr_players[indexPlayer]?.private_lesson_packs[indexPack]?.id !== undefined) {
                                        console.log("will delete");
                                        removeElementFromInnerArray(indexPlayer, indexPack);
                                        setFieldValue(
                                          `private_lesson_nbr_players[${indexPlayer}].private_lesson_packs`,
                                          values.private_lesson_nbr_players[indexPlayer].private_lesson_packs.filter((obj) => obj !== values.private_lesson_nbr_players[indexPlayer].private_lesson_packs[indexPack])
                                        );
                                        handleDeletePrPacks(values?.private_lesson_nbr_players[indexPlayer]?.private_lesson_packs[indexPack]?.id);
                                      } else {
                                        console.log("will delete local only");
                                        removeElementFromInnerArray(indexPlayer, indexPack);
                                        setFieldValue(
                                          `private_lesson_nbr_players[${indexPlayer}].private_lesson_packs`,
                                          values.private_lesson_nbr_players[indexPlayer].private_lesson_packs.filter((obj) => obj !== values.private_lesson_nbr_players[indexPlayer].private_lesson_packs[indexPack])
                                        );
                                      }
                                    }}
                                  />
                                )}
                                <Button
                                  variant="primary_active"
                                  sx={{ height: "35px" }}
                                  onClick={() => {
                                    if (isObjectEmpty(errors)) {
                                      if (values?.private_lesson_nbr_players[indexPlayer]?.private_lesson_packs[indexPack]?.id !== undefined) {
                                        if (values?.private_lesson_nbr_players[indexPlayer]?.private_lesson_packs[indexPack]?.nbr_lessons !== privateLessonData?.private_lesson_nbr_players[indexPlayer].private_lesson_packs[indexPack].nbr_lessons) {
                                          // console.log("nbr players has changed ==> send me both");

                                          console.log("will update");
                                          handleUpdatePacks(values?.private_lesson_nbr_players[indexPlayer]?.private_lesson_packs[indexPack]?.id, {
                                            unit_price: values?.private_lesson_nbr_players[indexPlayer]?.private_lesson_packs[indexPack]?.unit_price,
                                            nbr_lessons: values?.private_lesson_nbr_players[indexPlayer]?.private_lesson_packs[indexPack]?.nbr_lessons,
                                          });
                                        } else {
                                          // console.log("nbr players has NOT changed ==> send me unit price only");
                                          handleUpdatePacks(values?.private_lesson_nbr_players[indexPlayer]?.private_lesson_packs[indexPack]?.id, {
                                            unit_price: values?.private_lesson_nbr_players[indexPlayer]?.private_lesson_packs[indexPack]?.unit_price,
                                          });
                                        }
                                      } else {
                                        if (values?.private_lesson_nbr_players[indexPlayer]?.private_lesson_packs[indexPack]?.nbr_lessons !== undefined || values?.private_lesson_nbr_players[indexPlayer]?.private_lesson_packs[indexPack]?.unit_price !== undefined) {
                                          handleCreatePacks({
                                            private_lesson_nbr_player_id: values?.private_lesson_nbr_players[indexPlayer]?.id,
                                            nbr_lessons: values?.private_lesson_nbr_players[indexPlayer]?.private_lesson_packs[indexPack]?.nbr_lessons,
                                            unit_price: values?.private_lesson_nbr_players[indexPlayer]?.private_lesson_packs[indexPack]?.unit_price,
                                          });
                                        }
                                      }
                                    }
                                  }}
                                >
                                  Save
                                </Button>
                              </Box>
                            )}
                          </Box>
                        </Box>
                      </Box>
                    ))}

                    <Box>
                      <Button
                        sx={{ height: "38px" }}
                        variant="primary_active"
                        onClick={() => {
                          addElementToInnerArray(indexPlayer, 1);
                        }}
                      >
                        Add a pack
                      </Button>
                    </Box>
                  </Box>
                ))}

                <Box marginTop="16px">
                  <Button
                    sx={{ height: "38px" }}
                    variant="primary_active"
                    onClick={() => {
                      setPlayers([...players, [1]]);
                    }}
                  >
                    Add players
                  </Button>
                </Box>

                {/* <Box marginTop="16px" display="flex" flexDirection="row-reverse" gap="10px">
                  <Button sx={{ height: "38px" }} variant="secondary_gray">
                    Cancel
                  </Button>
                  <Button sx={{ height: "38px" }} variant="primary_active" type="submit">
                    Update
                  </Button>
                </Box> */}
              </Form>
            )}
          </Formik>
        </Box>
      </Modal>
    </ContentBox>
  );
}

export default PrivateLessonCard;
