import React, { useState } from "react";
import ContentBox from "../../components/ui/ContentBox";
import { useEffect } from "react";
import Api from "../../api/Api";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import giftIcon from "../../imgs/gift.svg";
import { Urls } from "../../api/Urls";
import tennisIcon from "../../imgs/tennisIcon.svg";
import iconCn from "../../imgs/cancel.svg";
import dropInIcon from "../../imgs/drop.png";
import { useAuthContext } from "../../hooks/useAuthContext";
import { ToastContainer, toast } from "react-toastify";
import { Box, Chip, Grid, IconButton, Typography, Button, Modal, Avatar } from "@mui/material";
import ButtonSquare from "../../components/ui/ButtonSquare";
import moment from "moment";
import cancelIcon from "../../imgs/cancelIcon.svg";
import { modalStyle, modalStyleOverflow } from "../../theme/ModalStyle";
import MySwitch from "../../components/ui/MySwitch/MySwitch";
import { colors } from "../../theme/Colors";
import FormikErrorText from "../../components/ui/FormikErrorText";
function PlayerMemberships({ bookId, cha, id }) {
  const { user } = useAuthContext();
  const access_token = user?.access_token;
  const [change, setChange] = useState();
  const [viewAll, setViewAll] = useState(false);
  const [memberships, setMemberships] = useState([]);
  const [openCancelSchedule, setOpenCancelSchedule] = useState({ open: false, id: null });
  const [membershipInfos, setMembershipInfos] = useState({});
  const [data, setData] = useState({});
  const [openMembership, setOpenMembership] = useState({ open: bookId ? true : false, id: { id: bookId } });
  const [opendeletemembership, setOpenDeleteMembership] = useState({ data: null, open: false });

  //get memeberships
  useEffect(() => {
    Api.get(Urls.GET_PLAYERS_BOOKING + id + "/bookings", {
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((response) => {
        if (viewAll) {
          setMemberships(response?.data?.data);
        } else {
          setMemberships(response?.data?.data?.filter((da) => da?.status === "confirmed"));
        }
        setData(response?.data);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  }, [change, cha, viewAll]);
  //handle Pagination
  const handlePagination = (link) => {
    //console.log("getting data");
    Api.get(`${link}`, {
      headers: { "Content-type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((res) => {
        //console.log("RESULTAT MATCHED offres DATA", res?.data);
        //console.log("RESPONES GHERBI", res);
        setData(res?.data);
        setMemberships(memberships?.concat(res.data?.data));
        //console.log(res?.data?.meta?.links, "link pag");
      })
      .catch((err) => {
        //console.log(err);
        toast.error(err?.response?.data?.message);
      });
  };
  useEffect(() => {
    document.title = "BTA admin - Player memberships";
  }, []);
  const handleClickcancel = (id) => {
    Api.post(
      Urls.POST_CANCEL_BOOKIGN + id + "/cancel",
      {},
      {
        headers: { "Content-type": "application/json", authorization: `Bearer ${access_token}` },
      }
    )
      .then((res) => {
        toast.success(res?.data?.message);
        setOpenMembership({ open: false, id: null });
        setChange(!change);
        setOpenDeleteMembership({ open: false, data: null });
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  };
  const handleCancelSchedule = (id) => {
    Api.post(
      Urls.CANCEL_APPOINTEMENT + id + "/cancel",
      {},
      {
        headers: { "Content-type": "application/json", authorization: `Bearer ${access_token}` },
      }
    )
      .then((res) => {
        toast.success(res?.data?.message);
        setOpenCancelSchedule({ open: false, id: null });
        setChange(!change);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  };
  useEffect(() => {
    if (openMembership?.open) {
      Api.get(Urls.GET_PLAYER_BOOKING + openMembership?.id?.id, {
        headers: { "Content-type": "application/json", authorization: `Bearer ${access_token}` },
      })
        .then((res) => {
          setMembershipInfos(res?.data?.data);
        })
        .catch((err) => {
          toast.error(err?.response?.data?.message);
        });
    }
  }, [openMembership, change]);
  return (
    <Box>
      <ToastContainer />

      <Box marginBottom="16px" display="flex" justifyContent="flex-end">
        <Box display="flex" gap="10px" flexDirection="row" alignItems="center">
          <Typography fontSize="20px" fontWeight={600} color={colors.darkgray}>
            View All
          </Typography>
          <MySwitch
            isOn={viewAll}
            onChange={() => {
              setViewAll(!viewAll);
            }}
          />
        </Box>
      </Box>
      <Grid container spacing={2}>
        {memberships?.map((membership) => (
          <Grid item xs={12} lg={4} md={4} sm={6}>
            {!membership?.private_lesson_pack_id && (membership?.program_duration || membership?.private_lesson_duration) && (
              <Box sx={{ cursor: "pointer" }} borderRadius="6px" border="1px solid #EF0000" width="100%">
                <ContentBox>
                  <Box display="flex" alignItems="start">
                    <Box onClick={() => setOpenMembership({ open: true, id: membership })} flex={2} display="flex" flexDirection="column" alignItems="center">
                      <Box>
                        <img src={giftIcon} alt="gift" />
                      </Box>
                      <Box>
                        <Typography fontSize="15px" fontWeight="500" lineHeight="21px" color="#4B465C">
                          {membership?.program_name}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography fontSize="13px" fontWeight="400" lineHeight="20px" color="#4B465C">
                          {membership?.program_duration ? membership?.program_duration : "-"} minutes -- {membership?.program_frequency_per_week ? membership?.program_frequency_per_week : " -"}/week
                        </Typography>
                      </Box>
                      <Box>
                        <Typography fontSize="13px" fontWeight="400" lineHeight="20px" color="#4B465C">
                          Subscribed at : {moment(membership?.start_date).format("DD/MM/YYYY")}
                        </Typography>
                      </Box>
                    </Box>
                    <Box display="flex" flexDirection="column" gap="5px" alignItems="center">
                      {membership?.level_name && <Chip variant="primary" label={membership?.level_name} />}
                      {membership?.status !== "cancelled" && (
                        <IconButton onClick={() => setOpenDeleteMembership({ data: membership, open: true })}>
                          <img src={iconCn} alt="cancel" />
                        </IconButton>
                      )}
                      {(membership?.status === "confirmed" || membership?.status === "cancelled") && (
                        <Chip
                          label={membership?.status === "confirmed" ? "CONFIRMED" : "CANCELED"}
                          sx={{
                            backgroundColor: `${membership?.status === "confirmed" ? "rgba(40, 199, 111, 0.16)" : "rgba(234, 84, 85, 0.16)"}`,
                            color: `${membership?.status === "confirmed" ? "rgba(40, 199, 111, 1)" : "rgba(234, 84, 85, 1)"}`,
                            borderRadius: "8px",
                          }}
                        />
                      )}
                      {membership?.status === "cancelled" && <FormikErrorText>canceled at : {membership?.cancelled_at?.split(" ")[0]}</FormikErrorText>}
                      {membership?.status === "pending" && <Chip label={"PENDING"} sx={{ backgroundColor: "rgba(255, 213, 128 , 0.5)", color: "orange", borderRadius: "8px" }} />}
                    </Box>
                  </Box>
                </ContentBox>
              </Box>
            )}
            {membership?.private_lesson_pack_id && (membership?.program_duration || membership?.private_lesson_duration) && (
              <Box sx={{ cursor: "pointer" }} borderRadius="6px" border="1px solid #EF0000" width="100%">
                <ContentBox>
                  <Box display="flex" alignItems="start">
                    <Box onClick={() => setOpenMembership({ open: true, id: membership })} flex={2} display="flex" flexDirection="column" alignItems="center">
                      <Box>
                        <img src={giftIcon} alt="gift" />
                      </Box>
                      <Box>
                        <Typography fontSize="15px" fontWeight="500" lineHeight="21px" color="#4B465C">
                          PRIVATE LESSON "{membership?.private_lesson_duration ? membership?.private_lesson_duration : "-"}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography fontSize="13px" fontWeight="400" lineHeight="20px" color="#4B465C">
                          {membership?.private_lesson_nbr_player ? membership?.private_lesson_nbr_player : "-"} Players - {membership?.private_lesson_nbr_lesson ? membership?.private_lesson_nbr_lesson : " -"} Lessons
                        </Typography>
                      </Box>
                      <Box>
                        <Typography fontSize="13px" fontWeight="400" lineHeight="20px" color="#4B465C">
                          Subscribed at : {moment(membership?.start_date).format("DD/MM/YYYY")}
                        </Typography>
                      </Box>
                    </Box>
                    <Box display="flex" flexDirection="column" gap="5px" alignItems="center">
                      {membership?.level_name && <Chip variant="primary" label={membership?.level_name} />}

                      {membership?.status !== "cancelled" && (
                        <IconButton onClick={() => setOpenDeleteMembership({ data: membership, open: true })}>
                          <img src={iconCn} alt="cancel" />
                        </IconButton>
                      )}
                      {(membership?.status === "confirmed" || membership?.status === "cancelled") && (
                        <Chip
                          label={membership?.status === "confirmed" ? "CONFIRMED" : "CANCELED"}
                          sx={{
                            backgroundColor: `${membership?.status === "confirmed" ? "rgba(40, 199, 111, 0.16)" : "rgba(234, 84, 85, 0.16)"}`,
                            color: `${membership?.status === "confirmed" ? "rgba(40, 199, 111, 1)" : "rgba(234, 84, 85, 1)"}`,
                            borderRadius: "8px",
                          }}
                        />
                      )}
                      {membership?.status === "pending" && <Chip label={"PENDING"} sx={{ backgroundColor: "rgba(255, 213, 128 , 0.5)", color: "orange", borderRadius: "8px" }} />}
                    </Box>
                  </Box>
                </ContentBox>
              </Box>
            )}
            {!membership?.program_duration && !membership?.private_lesson_duration && (
              <Box sx={{ cursor: "pointer" }} borderRadius="6px" border="1px solid #EF0000" width="100%">
                <ContentBox>
                  <Box justifyContent="center" display="flex" alignItems="center">
                    <Box onClick={() => setOpenMembership({ open: true, id: membership })} flex={2} display="flex" justifyContent="space-evenly" alignItems="center">
                      <Box>
                        <img style={{ height: "101px", width: "auto" }} src={dropInIcon} alt="dropin" />
                      </Box>
                      <Box>
                        <Typography fontSize="30px" color={colors?.main}>
                          DROP-IN
                        </Typography>
                      </Box>
                    </Box>
                    <Box display="flex" flexDirection="column" gap="5px" alignItems="center">
                      {membership?.level_name && <Chip variant="primary" label={membership?.level_name} />}

                      {membership?.status !== "cancelled" && (
                        <IconButton onClick={() => setOpenDeleteMembership({ data: membership, open: true })}>
                          <img src={iconCn} alt="cancel" />
                        </IconButton>
                      )}
                      <Typography fontSize="13px" fontWeight="400" lineHeight="20px" color="#4B465C">
                        {moment(membership?.start_date).format("DD/MM/YYYY")}
                      </Typography>
                    </Box>
                  </Box>
                </ContentBox>
              </Box>
            )}
          </Grid>
        ))}
        {data?.links?.next && (
          <Grid item xs={4}>
            <Box sx={{ cursor: "pointer" }} onClick={() => handlePagination(data?.links.next)} height="100%" width="100%" display="flex" justifyContent="center" alignItems="center">
              <AddCircleIcon color="red" />
            </Box>
          </Grid>
        )}
      </Grid>
      <Modal open={opendeletemembership?.open} onClose={() => setOpenDeleteMembership({ ...opendeletemembership, open: false })}>
        <Box sx={modalStyleOverflow}>
          <Box position="absolute" top="2px" right="2px">
            <ButtonSquare action="close" onClick={() => setOpenDeleteMembership({ ...opendeletemembership, open: false })} />
          </Box>
          <Box marginBottom="26px">
            <Typography textAlign="center" fontSize="26px" lineHeight="36px" fontWeight="500" color="#4B465C">
              Cancel membership
            </Typography>
          </Box>
          <Box>
            <Typography textAlign="center" fontSize="22px" lineHeight="32px" fontWeight="500" color="#4B465C">
              Are you sure you want to stop this membership?
            </Typography>
          </Box>
          <Box marginTop="16px" justifyContent="center" display="flex" gap="16px">
            <Button onClick={() => handleClickcancel(opendeletemembership.data?.id)} variant="primary_active">
              Confirm
            </Button>
            <Button onClick={() => setOpenDeleteMembership({ ...opendeletemembership, open: false })} variant="primary_inactive">
              Cancel
            </Button>
          </Box>
        </Box>
      </Modal>
      <Modal open={openCancelSchedule?.open} onClose={() => setOpenCancelSchedule({ id: null, open: false })}>
        <Box sx={modalStyleOverflow}>
          <Box position="absolute" top="2px" right="2px">
            <ButtonSquare action="close" onClick={() => setOpenCancelSchedule({ id: null, open: false })} />
          </Box>
          <Box marginBottom="26px">
            <Typography textAlign="center" fontSize="26px" lineHeight="36px" fontWeight="500" color="#4B465C">
              Cancel schedule
            </Typography>
          </Box>
          <Box>
            <Typography textAlign="center" fontSize="22px" lineHeight="32px" fontWeight="500" color="#4B465C">
              Are you sure you want to stop this schedule?
            </Typography>
          </Box>
          <Box marginTop="16px" justifyContent="center" display="flex" gap="16px">
            <Button onClick={() => handleCancelSchedule(openCancelSchedule?.id)} variant="primary_active">
              Confirm
            </Button>
            <Button onClick={() => setOpenCancelSchedule({ id: null, open: false })} variant="primary_inactive">
              Cancel
            </Button>
          </Box>
        </Box>
      </Modal>
      <Modal open={openMembership?.open} onClose={() => setOpenMembership({ open: false, id: null })}>
        <Box sx={modalStyleOverflow}>
          <Box position="absolute" top="2px" right="2px">
            <ButtonSquare action="close" onClick={() => setOpenMembership({ open: false, id: null })} />
          </Box>
          <Box marginBottom="26px">
            <Typography fontSize="26px" lineHeight="36px" fontWeight="500" color="#4B465C">
              subscribed at : {membershipInfos?.start_date}
            </Typography>
          </Box>
          <Box display="flex" flexDirection="column" gap="16px">
            <Box display="flex" alignItems="center" justifyContent="space-between">
              <Box>
                <Typography fontSize="22px" fontWeight="500" lineHeight="30px">
                  {membershipInfos?.program_name ? "Program" : "PRIVATE LESSON"}
                </Typography>
              </Box>
              <Box>
                {membershipInfos?.status !== "cancelled" && (
                  <Button variant="primary_active">
                    <Box onClick={() => setOpenDeleteMembership({ data: openMembership?.id, open: true })} display="flex" gap="10px">
                      <img src={cancelIcon} alt="cancel" />
                      <Typography color="white">Cancel booking</Typography>
                    </Box>
                  </Button>
                )}
              </Box>
            </Box>
            {membershipInfos?.program_name && (
              <Box>
                <Typography fontSize="15px" fontWeight="600" lineHeight="22px" color="#4B465C">
                  {openMembership?.id?.program_name ? openMembership?.id?.program_name : membershipInfos?.court_schedules ? membershipInfos?.court_schedules[0]?.program_name : "-"} {openMembership?.id?.program_duration ? openMembership?.id?.program_duration + "min" : membershipInfos?.court_schedules ? membershipInfos?.court_schedules[0]?.program_duration + "min" : "-"}
                </Typography>
              </Box>
            )}
            <Box>
              <Typography fontSize="15px" fontWeight="600" lineHeight="22px" color="#4B465C">
                Schedules
              </Typography>
            </Box>
            <Box display="flex" flexDirection="column" gap="5px">
              {membershipInfos?.court_schedules?.map((courtS) => (
                <Box display="flex" alignItems="center" justifyContent="space-between">
                  <Box display="flex" gap="16px" alignItems="center">
                    <Avatar variant="rounded" sx={{ background: "white" }}>
                      <img src={tennisIcon} alt="court schedule" />
                    </Avatar>
                    <Box>
                      <Typography fontSize="15px" lineHeight="21px" fontWeight="500" color="#4B465C">
                        court {courtS?.court_name} - {courtS?.court_address}
                      </Typography>
                      <Typography fontSize="13px" fontWeight="400" lineHeight="21px" color="#4B465C">
                        {courtS?.start_datetime?.split(" ")[0]} from {moment(courtS?.start_datetime)?.format("HH:mm A")} to {moment(courtS?.end_datetime)?.format("HH:mm A")}
                      </Typography>
                    </Box>
                  </Box>
                  {!courtS?.cancelled_at && (
                    <Box>
                      <Button onClick={() => setOpenCancelSchedule({ id: membershipInfos?.id, open: true })} variant="primary_active">
                        Cancel
                      </Button>
                    </Box>
                  )}
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}

export default PlayerMemberships;
