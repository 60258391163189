import { Autocomplete, Box, Button, CircularProgress, IconButton, Modal, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import styled from "@emotion/styled";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import ContentBox from "../../../components/ui/ContentBox";
import { data } from "../../../components/data/users";
import UserType from "../../../components/ui/UserType";
import * as yup from "yup";

import TableShowUser from "../../../components/ui/TableShowUser";
import ButtonSquare from "../../../components/ui/ButtonSquare";
import { useNavigate } from "react-router-dom";
import Api from "../../../api/Api";
import { Urls } from "../../../api/Urls";
import { useAuthContext } from "../../../hooks/useAuthContext";
import moment from "moment";
import { ErrorMessage, Field, Form, Formik } from "formik";
import FormikErrorText from "../../../components/ui/FormikErrorText";
import BtaDatePicker from "../../../components/ui/BtaDatePicker";
import { modalStyle } from "../../../theme/ModalStyle";
import { ToastContainer, toast } from "react-toastify";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DeleteOutline } from "@mui/icons-material";
function Payslips({ id }) {
  const { user } = useAuthContext();
  const [typeUser, setTypeUser] = useState("");
  const [search, setSearch] = useState("");

  const navigate = useNavigate();
  const access_token = user?.access_token;

  //table style
  const types = ["User", "Admin", "Coach"];
  const genders = ["MALE", "FEMALE"];
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState({
    month: null,
    year: null,
  });
  const [pay, setPay] = useState([]);
  const [data, setData] = useState({});

  useEffect(() => {
    document.title = "BTA admin - Coaches";
  }, []);
  useEffect(() => {
    Api.get(Urls.GET_COACH_REPORTS, {
      headers: { "Content-Type": "application/json", authorization: `Bearer ${access_token}` },
      params: {
        coach_id: id,
        ...(filter?.month && filter?.year ? { start_date: `${moment(filter?.year).format("YYYY")}-${moment(filter.month).format("MM")}-01` } : null),
        ...(filter?.month && filter?.year ? { end_date: `${moment(filter?.year).format("YYYY")}-${moment(filter.month).format("MM")}-01` } : null),
      },
    })
      .then((res) => {
        console.log(res);
        setPay(res?.data?.data);
        setLoading(false);
        setData(res?.data);
      })
      .catch((err) => {
        setLoading(false);

        toast.error(err?.response?.data?.message);
      });
  }, [filter]);
  //handle Pagination
  const handlePagination = (link) => {
    //console.log("getting data");
    Api.get(`${link}`, {
      headers: { "Content-type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((res) => {
        //console.log("RESULTAT MATCHED offres DATA", res?.data);
        //console.log("RESPONES GHERBI", res);
        setData(res?.data);
        setPay(res.data?.data);
        //console.log(res?.data?.meta?.links, "link pag");
      })
      .catch((err) => {
        //console.log(err);
        setPay([]);
      });
  };
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "white",
      paddingTop: "8px",
      paddingBottom: "8px",
      letterSpacing: "1px",
      color: `#4B465C`,
      fontSize: 13,
      fontWeight: 500,
    },
    [`&.${tableCellClasses.body}`]: {
      background: "white",
      fontSize: 14,
      color: `#666D83`,
      fontWeight: "500",
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
    ":hover": {
      filter: "brightness(0.95)",
    },
    cursor: "pointer",
  }));

  const StyledTable = styled(Table)({
    borderRadius: "0px",
  });
  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };
  //get users

  return (
    <Box display="flex" flexDirection="column" gap="26px">
      <ToastContainer />
      <Box>
        <Typography fontSize="22px" fontWeight="500" lineHeight="30px" color="#4B465C">
          Payslips
        </Typography>
      </Box>
      <ContentBox>
        <Box display="flex" flexDirection="column" gap="16px">
          <Box>
            <Typography fontSize="18px" fontWeight="500" lineHeight="24px" color="#4B465C">
              List of payslips
            </Typography>
          </Box>
          <Box justifyContent="space-between" marginTop="8px" display="flex" gap="16px" flexWrap="wrap" flexDirection="row" alignItems="center">
            <Box display="flex" alignItems="center" gap="16px">
              <Box display="flex" alignItems="center" gap="5px">
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker inputFormat="MM" views={["month"]} value={filter?.month} onChange={(e) => setFilter({ ...filter, month: e })} renderInput={(params) => <TextField {...params} />} />
                </LocalizationProvider>
                <IconButton onClick={() => setFilter({ ...filter, month: null })}>
                  <DeleteOutline />
                </IconButton>
              </Box>
              <Box display="flex" alignItems="center" gap="5px">
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker inputFormat="yyyy" views={["year"]} onChange={(e) => setFilter({ ...filter, year: e })} value={filter?.year} renderInput={(params) => <TextField {...params} />} />
                </LocalizationProvider>
                <IconButton onClick={() => setFilter({ ...filter, year: null })}>
                  <DeleteOutline />
                </IconButton>
              </Box>
            </Box>
          </Box>
          {loading && (
            <Box display="flex" justifyContent="center">
              <CircularProgress />
            </Box>
          )}
          {!loading && (
            <TableContainer sx={{ borderRadius: "0px", border: `1px solid #DBDADE` }}>
              <StyledTable aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell width="10%">ID</StyledTableCell>
                    <StyledTableCell width="70%">MONTH - YEAR</StyledTableCell>
                    <StyledTableCell width="20%" align="center">
                      GENERATED INCOME
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {pay?.map((p) => (
                    <StyledTableRow key={user?.id}>
                      <StyledTableCell align="left">
                        <Typography fontWeight="400" color="#3890E2" fontSize="15px">
                          #{p?.id}
                        </Typography>
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {moment(p?.date).format("MMMM")} - {moment(p?.date).format("YYYY")}
                      </StyledTableCell>
                      <StyledTableCell align="center">{p?.total_cost}BD</StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </StyledTable>
            </TableContainer>
          )}
          {pay?.length === 0 && !loading && <Typography textAlign="center">No payslips</Typography>}
          {pay?.length > 0 && (
            <Box justifyContent="flex-end" display="flex" gap="4px">
              {data?.links?.prev && (
                <ButtonSquare
                  onClick={() => {
                    handlePagination(data?.links?.prev);
                    window.scrollTo({
                      top: 0,
                      behavior: "smooth",
                    });
                  }}
                  action="Previous"
                />
              )}
              <Box display="flex" gap="4px" justifyContent="center">
                {data?.meta?.links?.map((link) => (
                  <ButtonSquare
                    onClick={() => {
                      handlePagination(link.url);
                      window.scrollTo({
                        top: 0,
                        behavior: "smooth",
                      });
                    }}
                    action={link?.active ? "number-active" : "number"}
                    number={link.label}
                  />
                ))}
              </Box>
              {data?.links?.next && (
                <ButtonSquare
                  onClick={() => {
                    handlePagination(data?.links?.next);
                    window.scrollTo({
                      top: 0,
                      behavior: "smooth",
                    });
                  }}
                  action="Next"
                />
              )}
            </Box>
          )}
        </Box>
      </ContentBox>
    </Box>
  );
}

export default Payslips;
