import { Box, Typography, Avatar } from "@mui/material";

import personIcon from "../../imgs/personIcon.svg";
import calendarIcon from "../../imgs/calendarIcon.svg";
import ContentBox from "../../components/ui/ContentBox";
import phoneIcon from "../../imgs/phoneIcon.svg";
import emailIcon from "../../imgs/emailIcon.svg";
import photo from "../../imgs/admin-picture.png";
import React from "react";
import { colors } from "../../theme/Colors";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

function PlayerInformations({ player }) {
  const navigate = useNavigate();
  useEffect(() => {
    document.title = "BTA admin - Player informations";
  }, []);
  return (
    <Box display="flex" gap="26px" flexDirection="column">
      {/* player detail infos */}
      <Box display="flex" sx={{ flexDirection: { sm: "row", xs: "column" } }} gap="26px">
        <ContentBox maxWidth="445px">
          <Box display="flex" flexDirection="column" gap="27px">
            <Box display="flex" flexDirection="column" gap="17px">
              <Typography fontWeight="400" fontSize="13px" lineHeight="20px" color="#4B465C">
                ABOUT
              </Typography>
              <Box display="flex" flexDirection="column" gap="14px">
                <Box display="flex" gap="6px" alignItems="start">
                  <img src={personIcon} alt="position" />
                  <Typography fontSize="15px" lineHeight="22px" fontWeight="600" color="#4B465C">
                    Full name :
                  </Typography>
                  <Typography fontSize="15px" lineHeight="22px" fontWeight="400" color="#4B465C">
                    {player?.first_name} {player?.last_name}
                  </Typography>
                </Box>
                <Box display="flex" gap="6px" alignItems="start">
                  <img src={calendarIcon} alt="position" />
                  <Typography fontSize="15px" lineHeight="22px" fontWeight="600" color="#4B465C">
                    Date of birth :
                  </Typography>
                  <Typography fontSize="15px" lineHeight="22px" fontWeight="400" color="#4B465C">
                    {player?.date_of_birth}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </ContentBox>

        {/* associated account */}
        <Box sx={{ ":hover": { filter: "brightness(0.95)" }, cursor: "pointer" }}>
          <ContentBox
            onClick={() => {
              navigate("/admin/users/" + player?.client?.id, { state: { role: "client" } });
            }}
            maxWidth="445px"
          >
            <Box display="flex" flexDirection="column" gap="27px" justifyContent="space-between">
              <Box>
                <Typography fontWeight="400" fontSize="13px" lineHeight="20px" color="#4B465C">
                  ASSOCIATED ACCOUNT
                </Typography>
              </Box>
              <Box display="flex" flexDirection="row" gap="14px" alignItems="center">
                <Box>
                  <Avatar variant="circular" sx={{ height: "100px", width: "100px" }}>
                    <img src={player?.client?.url_avatar} alt="" />
                  </Avatar>
                </Box>
                <Box display="flex" flexDirection="column" gap="5px">
                  <Typography fontSize="22px" fontWeight={500} color={colors.darkgray}>
                    {player?.client?.first_name} {player?.client?.last_name}
                  </Typography>
                  <Typography fontSize="15px" fontWeight={500} color={colors.lightgray}>
                    {player?.client?.email}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </ContentBox>
        </Box>
      </Box>
    </Box>
  );
}

export default PlayerInformations;
