import { Autocomplete, Box, Button, TextField, Typography, Avatar, Chip, Modal } from "@mui/material";
import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import styled from "@emotion/styled";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import ContentBox from "../../../components/ui/ContentBox";
import MySwitch from "../../../components/ui/MySwitch/MySwitch";
import ButtonSquare from "../../../components/ui/ButtonSquare";
import { useNavigate } from "react-router-dom";
import { terms_rows } from "../../../components/data/termsRows";
import Api from "../../../api/Api";
import { Urls } from "../../../api/Urls";
import { useAuthContext } from "../../../hooks/useAuthContext";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Formik, Field, Form, ErrorMessage } from "formik";
import FormikErrorText from "../../../components/ui/FormikErrorText";
import BtaDatePicker from "../../../components/ui/BtaDatePicker";
import { modalStyle, modalStyleOverflow } from "../../../theme/ModalStyle";
import { courts_columns } from "../../../components/data/courtsColumns";
import * as Yup from "yup";
import { products_rows } from "../../../components/data/productsRows";
import { products_columns } from "../../../components/data/productsColumns";
import BreadcrumComp from "../../../components/ui/BreadcrumComp";

function Products() {
  const { user } = useAuthContext();
  const access_token = user?.access_token;
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [data, setData] = useState([]);
  const [products, setProducts] = useState([]);
  const [typeProd, setTypeProd] = useState(null);
  const [types, setTypes] = useState([]);
  const [didDelete, setDidDelete] = useState(false);
  const [openDeleteProduct, setOpenDeleteProduct] = useState(false);
  const [selectedRowToDelete, setSelectedRowToDelete] = useState();
  useEffect(() => {
    document.title = "BTA admin - Store / Products";
  }, []);

  useEffect(() => {
    Api.get(Urls.STORE_GET_CATEGORIES, {
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((response) => {
        console.log("categories list:", response.data.data);
        setTypes(response?.data?.data);
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
      });
  }, []);
  const handleCloseDeleteProduct = () => {
    setOpenDeleteProduct(false);
  };

  const handleOpenDeleteProduct = () => {
    setOpenDeleteProduct(true);
  };

  const handleDeleteProduct = () => {
    Api.delete(Urls.STORE_PRODUCT_DELETE + `/${selectedRowToDelete}`, {
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((response) => {
        toast.success(response?.data?.message);
        setDidDelete(!didDelete);
        setOpenDeleteProduct(false);
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
      });
  };

  //handle Pagination
  const handlePagination = (link) => {
    console.log("getting data");
    Api.get(`${link}`, {
      headers: { "Content-type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((res) => {
        console.log("RESULTAT products", res?.data);
        setData(res?.data);
        setProducts(res.data?.data);
        console.log(res?.data?.meta?.links, "link page");
      })
      .catch((err) => {
        console.log(err);
        setProducts([]);
      });
  };

  //table style
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "white",
      paddingTop: "8px",
      paddingBottom: "8px",
      letterSpacing: "1px",
      color: `#4B465C`,
      fontSize: 13,
      fontWeight: 500,
    },
    [`&.${tableCellClasses.body}`]: {
      background: "white",
      fontSize: 14,
      color: `#666D83`,
      fontWeight: "500",
    },
  }));

  useEffect(() => {
    Api.get(Urls.STORE_GET_PRODUCTS, {
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      params: {
        ...(search && { name: search }),
        ...(typeProd && { category_id: typeProd?.id }),
      },
    })
      .then((response) => {
        console.log("products list:", response.data.data);
        setProducts(response?.data?.data);
        setData(response?.data);
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
      });
  }, [didDelete, search, typeProd]);

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
    ":hover": {
      filter: "brightness(0.95)",
    },
    cursor: "pointer",
  }));

  const StyledTable = styled(Table)({
    borderRadius: "0px",
  });

  return (
    <React.Fragment>
      <ToastContainer />
      <Box sx={{ display: "flex", flexDirection: "column", gap: "26px", marginTop: "26px" }}>
        <Box>
          <Box>
            <BreadcrumComp first="Store" second="Products" />
          </Box>
        </Box>
        <ContentBox>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
            <Box>
              <Typography fontSize="18px" fontWeight="500" lineHeight="24px" color="#4B465C">
                Products
              </Typography>
            </Box>
            <Box sx={{ marginTop: "8px", flexWrap: "wrap", display: "flex", gap: "16px", flexDirection: "row-reverse", justifyContent: "space-between" }}>
              <Box>
                <Button sx={{ height: "38px" }} variant="primary_active" onClick={() => navigate("/admin/store/products/add")}>
                  Create a product
                </Button>
              </Box>
              <Box display="flex" gap="10px">
                <TextField value={search} onChange={(e) => setSearch(e.target.value)} type="input" placeholder="Search product" />
                <Box>
                  <Autocomplete
                    sx={{ minWidth: "300px" }}
                    options={types}
                    value={typeProd}
                    getOptionLabel={(e) => e?.name}
                    isOptionEqualToValue={(option, value) => option?.id === value?.id}
                    onChange={(event, value) => {
                      if (value !== null) {
                        //console.log("selecting facture state value", value);
                        //console.log("selecting facture state value", value.id);
                        setTypeProd(value);
                        //console.log("selectedFicheDePaieState", selectedFicheDePaieState);
                      } else {
                        setTypeProd(null);
                      }
                    }}
                    renderInput={(params) => <TextField {...params} name="autocomplete" placeholder="Select type product" />}
                  />
                </Box>
              </Box>
            </Box>
            <TableContainer sx={{ borderRadius: "0px", border: `1px solid #DBDADE` }}>
              <StyledTable aria-label="customized table">
                <TableHead>
                  <TableRow>
                    {products_columns.map((col, index) => (
                      <StyledTableCell key={index} width={col.width} align={col.align}>
                        {col.name}
                      </StyledTableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {products?.map((product) => (
                    <StyledTableRow key={product?.id}>
                      <StyledTableCell align="left">
                        <Typography fontWeight="400" color="#3890E2" fontSize="15px">
                          #{product?.id}
                        </Typography>
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        <Box sx={{ display: "flex", flexDirection: "row", gap: "10px", alignItems: "center" }}>
                          <Avatar src={product?.files[0]?.url_file} variant="rounded" width="38px" height="38px" />
                          <Typography>{product?.name} </Typography>
                        </Box>
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        <Box sx={{ display: "flex", flexDirection: "row", gap: "10px", alignItems: "center" }}>
                          <Avatar src={product?.category?.url_file} width="30px" height="30px" />
                          <Typography>{product?.category?.name} </Typography>
                        </Box>
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        <Chip variant={product?.is_published === 1 ? "green" : "red"} label={product?.is_published === 1 ? "Published" : "Unpublished"} />
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        <Chip variant={product?.availability === 1 ? "green" : "red"} label={product?.availability === 1 ? "Available" : "Unavailable"} />
                      </StyledTableCell>
                      <StyledTableCell align="left">{product?.price} BD</StyledTableCell>
                      <StyledTableCell align="left">
                        <Box justifyContent="left" display="flex" gap="16px">
                          <ButtonSquare
                            action="edit_outlined"
                            onClick={() => {
                              navigate(`/admin/store/products/update/${product?.id}`);
                            }}
                          />
                          <ButtonSquare
                            action="delete_red"
                            onClick={() => {
                              setSelectedRowToDelete(product.id);
                              handleOpenDeleteProduct();
                            }}
                          />
                        </Box>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </StyledTable>
            </TableContainer>

            {/* modal delete */}
            <Modal open={openDeleteProduct} onClose={handleCloseDeleteProduct}>
              <Box sx={modalStyleOverflow}>
                <Box position="absolute" top="2px" right="2px">
                  <ButtonSquare action="close" onClick={handleCloseDeleteProduct} />
                </Box>
                <Box display="flex" flexDirection="column" gap="16px">
                  <Typography fontSize="26px" lineHeight="36px" fontWeight="500" color="#4B465C">
                    Delete product
                  </Typography>
                  <Typography fontSize="16px" fontWeight="400" color="#4B465C">
                    Are you sure you want to delete product {`#${selectedRowToDelete}`} ?
                  </Typography>
                  <Box display="flex" flexDirection="row-reverse" gap="10px">
                    <Button variant="secondary_gray" sx={{ height: "38px" }} onClick={handleCloseDeleteProduct}>
                      Cancel
                    </Button>
                    <Button variant="primary_active" sx={{ height: "38px" }} onClick={() => handleDeleteProduct(selectedRowToDelete)}>
                      Delete
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Modal>

            <Box display="flex" gap="10px" flexDirection="row-reverse">
              <Box>
                {data?.links?.next && (
                  <>
                    <Button
                      onClick={() => {
                        handlePagination(data?.links?.next);
                        window.scrollTo({
                          top: 0,
                          behavior: "smooth",
                        });
                      }}
                      variant="mj_secondary_md"
                    >
                      Next
                    </Button>
                  </>
                )}
              </Box>

              <Box display="flex" gap="10px">
                {data?.meta?.links?.map((link, index) => (
                  <ButtonSquare
                    key={index}
                    onClick={() => {
                      handlePagination(link.url);
                      window.scrollTo({
                        top: 0,
                        behavior: "smooth",
                      });
                    }}
                    action={link?.active ? "number-active" : "number"}
                    number={link.label}
                  />
                ))}
              </Box>

              <Box>
                {data?.links?.prev && (
                  <>
                    <Button
                      onClick={() => {
                        handlePagination(data?.links?.prev);
                        window.scrollTo({
                          top: 0,
                          behavior: "smooth",
                        });
                      }}
                      variant="mj_secondary_md"
                    >
                      Previous
                    </Button>
                  </>
                )}
              </Box>
            </Box>
          </Box>
        </ContentBox>
      </Box>
    </React.Fragment>
  );
}

export default Products;
