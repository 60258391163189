import React from "react";
import { Box, Typography } from "@mui/material";
import ContentBox from "../ui/ContentBox";
import personIcon from "../../imgs/personIcon.svg";
import positionIcon from "../../imgs/positionIcon.svg";
import phoneIcon from "../../imgs/phoneIcon.svg";
import calendarIcon from "../../imgs/calendarIcon.svg";
import emailIcon from "../../imgs/emailIcon.svg";
function UserInfos({ birthday, type, id, fullName, location, email, contact }) {
  return (
    <Box>
      <ContentBox>
        <Box display="flex" flexDirection="column" gap="27px">
          <Box display="flex" flexDirection="column" gap="17px">
            <Typography fontWeight="400" fontSize="13px" lineHeight="20px" color="#4B465C">
              ABOUT
            </Typography>
            <Box display="flex" flexDirection="column" gap="14px">
              <Box display="flex" gap="6px" alignItems="start">
                <img src={personIcon} alt="position" />
                <Typography fontSize="15px" lineHeight="22px" fontWeight="600" color="#4B465C">
                  Full name
                </Typography>
                <Typography fontSize="15px" lineHeight="22px" fontWeight="400" color="#4B465C">
                  {fullName ? fullName : "-"}
                </Typography>
              </Box>

              {type !== "super_admin" && (
                <Box display="flex" gap="6px" alignItems="start">
                  <img src={calendarIcon} alt="position" />
                  <Typography fontSize="15px" lineHeight="22px" fontWeight="600" color="#4B465C">
                    Date of birth
                  </Typography>
                  <Typography fontSize="15px" lineHeight="22px" fontWeight="400" color="#4B465C">
                    {birthday ? birthday : "-"}
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>
          <Box display="flex" flexDirection="column" gap="17px">
            <Typography fontWeight="400" fontSize="13px" lineHeight="20px" color="#4B465C">
              CONTACTS
            </Typography>
            <Box display="flex" flexDirection="column" gap="14px">
              <Box display="flex" gap="6px" alignItems="start">
                <img src={phoneIcon} alt="position" />
                <Typography fontSize="15px" lineHeight="22px" fontWeight="600" color="#4B465C">
                  Contact
                </Typography>
                <Typography fontSize="15px" lineHeight="22px" fontWeight="400" color="#4B465C">
                  {contact ? contact : "-"}
                </Typography>
              </Box>
              <Box display="flex" gap="6px" alignItems="start">
                <img src={emailIcon} alt="position" />
                <Typography fontSize="15px" lineHeight="22px" fontWeight="600" color="#4B465C">
                  Email
                </Typography>
                <Typography fontSize="15px" lineHeight="22px" fontWeight="400" color="#4B465C">
                  {email ? email : "-"}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </ContentBox>
    </Box>
  );
}

export default UserInfos;
